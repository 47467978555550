import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import "./App.css"

import Weekly from "./components/weekly"
import Pickpoint from "./components/pickpoint"
import Daily from "./components/daily"
import Monthly from "./components/mothly"
import Contact from "./components/contact"
import Vaccine from "./components/vaccine"
import Register from "./components/register"
import Home from "./components/home"

const App = () => {
  return (
    <Router>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/register">
        <Register />
      </Route>
      <Route path="/weekly">
        <Weekly />
      </Route>
      <Route path="/ppp">
        <Pickpoint />
      </Route>
      <Route path="/daily">
        <Daily />
      </Route>
      <Route path="/monthly">
        <Monthly />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/vaccine">
        <Vaccine />
      </Route>
    </Router>
  )
}

export default App
