import React, { useState, useEffect } from "react"
import HRConnect from "../../apis/hrconnect"
import { useHistory } from "react-router-dom"

const liff = window.liff

const Register = () => {
  let history = useHistory()
  const [profile, setProfile] = useState({})
  const [thaiid, setThaiid] = useState("")
  const [secret, setSecret] = useState("")
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (Object.keys(profile).length === 0) {
      liff.init(
        { liffId: "1653813164-zlOJOq61" },
        async () => {
          if (liff.isLoggedIn()) {
            setProfile(await liff.getProfile())
          } else {
            //liff.login({ redirectUri: 'http://localhost:3000/register' })
            liff.login()
          }
        },
        (e) => console.log(e.message)
      )
    }
    console.log(profile)

    if (profile?.userId && !user) {
      HRConnect.post("/initUsers/info", { q: profile.userId })
        .then((res) => {
          console.log(res.data)
          if (res.data.length > 0) setUser(res.data[0])
        })
        .catch((e) => console.log(e.message))
    }
  }, [profile, user])

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(thaiid, secret)
    liff
      .sendMessages([
        {
          type: "text",
          text: "ลงทะเบียน " + thaiid + " " + secret,
        },
      ])
      .then(() => {
        console.log("message sent")
        liff.closeWindow()
      })
      .catch((err) => {
        console.log("error", err)
        liff.closeWindow()
      })
  }

  if (user) {
    history.replace("/")
  }

  return (
    <div class="ui raised segment">
      <div>
        สวัสดี {profile.displayName} {user?.full_name}
      </div>
      <form class="ui form" onSubmit={handleSubmit}>
        <div class="field">
          <label>เลขบัตรประชาชน</label>
          <input
            type="text"
            name="thai_id"
            placeholder="Thai National ID"
            onChange={(e) => setThaiid(e.target.value)}
          />
        </div>
        <div class="field">
          <label>รหัสยืนยันตัวตน</label>
          <input
            type="text"
            name="secret_code"
            placeholder="Secret Code"
            onChange={(e) => setSecret(e.target.value)}
          />
        </div>
        <button class="ui button" type="submit">
          สมัครสมาชิก
        </button>
      </form>
    </div>
  )
}

export default Register
