import React, { useState, useEffect } from "react"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import HRConnect from "../../apis/hrconnect"

const liff = window.liff

const Home = () => {
  const [profile, setProfile] = useState({})
  const [user, setUser] = useState(null)
  const [paySlip, setPaySlip] = useState([])

  const MySwal = withReactContent(Swal)

  useEffect(() => {
    if (Object.keys(profile).length === 0) {
      liff.init(
        { liffId: "1653813164-3wKEKRza" },
        async () => {
          if (liff.isLoggedIn()) {
            setProfile(await liff.getProfile())
          } else {
            liff.login()
          }
        },
        (e) => console.log(e.message)
      )
    }
    console.log(profile)

    if (profile?.userId && !user) {
      HRConnect.post("/initUsers/info", { q: profile.userId })
        .then((res) => {
          console.log(res.data)
          if (res.data.length > 0) setUser(res.data[0])
        })
        .catch((e) => console.log(e.message))
    }

    if (user) {
      HRConnect.post("/paySlip/paySlipById", {
        id: user?.thai_id,
        code: user?.emp_code,
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.length > 0) setPaySlip(res.data)
        })
        .catch((e) => console.log(e.message))
    }
  }, [profile, user])

  return (
    <React.Fragment>
      <div className="ui fixed inverted menu">
        <div className="ui container">
          <div className="header item">HRConnect</div>
          <a href="#" className="item">
            Home
          </a>

          {/* <div className="ui simple dropdown item">
            บริการอื่น <i className="dropdown icon"></i>
            <div className="menu">
              <a className="item" href="#">
                สลิปเงินเดือน
              </a>
              <a className="item" href="#">
                Link Item
              </a>
              <div className="divider"></div>
              <div className="header">Header Item</div>
              <div className="item">
                <i className="dropdown icon"></i>
                Sub Menu
                <div className="menu">
                  <a className="item" href="#">
                    Link Item
                  </a>
                  <a className="item" href="#">
                    Link Item
                  </a>
                </div>
              </div>
              <a className="item" href="#">
                Link Item
              </a>
            </div>
          </div> */}
        </div>
      </div>

      <div className="ui main text container ">
        <br />
        <br />
        <div className="ui raised segments">
          <div className="ui segment">
            <h3>ข้อมูลส่วนตัว</h3>
            <p>
              <b>ชื่อ :</b> {user?.full_name}
            </p>
            <p>
              <b>ชื่อเล่น :</b> {user?.nick_name}
            </p>
            <p>
              <b>Name :</b> {user?.eng_name} {user?.eng_surname}
            </p>
            <p>
              <b>รหัสพนักงาน :</b> {user?.emp_code}
            </p>
            <p>
              <b>PUIC :</b> {user?.puic}
            </p>
            <p>
              <b>สังกัด/ตำแหน่ง :</b> {user?.title}
            </p>
          </div>
          <div className="ui segment">
            <h3>ข้อมูลติดต่อ</h3>
            <p>
              <b>อีเมล :</b>
              {user?.email}
            </p>
            <p>
              <b>มือถือ(บริษัท) :</b>
              {user?.company_phone_no}
            </p>
            <p>
              <b>มือถือ(ส่วนตัว) :</b>
              {user?.personal_phone_no}
            </p>
            <p>
              <b>หมายเลขภายใน :</b>
              {user?.internal_extension}
            </p>
          </div>
          <div className="ui segment">
            <h3>สลิปเงินเดือน</h3>
            {paySlip.length === 0 ? (
              <button className="ui disabled button">ไม่พบข้อมูล</button>
            ) : null}
            {paySlip.map((p) => {
              return (
                <button
                  key={p.id}
                  className="ui blue button"
                  onClick={() => {
                    Swal.fire({
                      title: "การอนุญาติในการเก็บข้อมูล",
                      html: "<p>ระบบจะทำการบันทึกวันเวลา และข้อมูลที่จำเป็น ในการรับข้อมูลสลิปเงินเดือนในระบบ Hr Connect </p> <p>อนุญาติให้เก็บข้อมูล?</p>",
                      showDenyButton: true,
                      showCancelButton: true,
                      confirmButtonText: "อนุญาติ",
                      denyButtonText: `ไม่อนุญาติ`,
                      showLoaderOnConfirm: true,
                      preConfirm: async () => {
                        console.log("Pre Confirm")
                        const browserInfo = navigator.userAgent
                        console.log(browserInfo)

                        const respose = await fetch(
                          "https://api64.ipify.org?format=json"
                        )
                        const data = await respose.json()

                        console.log(data.ip)

                        const response = await HRConnect.post(
                          "/paySlip/PaySlipLog",
                          {
                            line_uid: profile.userId,
                            pay_slip_id: p.id,
                            ip_address: data.ip,
                            user_agent: browserInfo,
                          }
                        )

                        console.log(response.data)
                      },
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        window.location.href = p.link
                      } else if (result.isDenied) {
                        Swal.fire(
                          "กรุณาติดต่อฝ่ายบุคคล เพื่อแจ้งการรับสลิปเงินเดือนในช่องทางอื่น",
                          "",
                          "info"
                        )
                      }
                    })
                  }}
                  style={{ marginBottom: "2px" }}
                >
                  {p.pay_date}
                </button>
              )
            })}
          </div>
          <div className="ui segment">
            <button className="ui disabled button">แจ้งแก้ไขข้อมูล</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
